
import baseConstants from '../../store/base/-constants'

export default {
  name: 'OrganismSidebarUniverse',
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen
    }
  }
}
